export const hNumParams = {
    lineL:10,
    lineB:-405,
    strongR:-5,
    strongB:3,
    numRotation:0,
    lRotation:0
  }
  
  export const vNumParams = {
    lineL:-208,
    lineB:-163,
    strongR:0,
    strongB:0,
    numRotation:90,
    lRotation:-90
  }