export const opsdata = [
    {
        // style:{left:'240px'},
        display:'+',
        val:'+',
        operator:true,
    },

    {
        style:{top:'80px'},
        display:'-',
        val:'-',
        operator:true,
    },

    {
        style:{top:'160px'},
        display:'×',
        val:'*',
        operator:true,
    },

    {
        style:{top:'240px'},
        val:'/',
        display:'÷',
        operator:true,
    },
]